<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-card>
          <b-form-group
            label="Müşteri Ara"
            label-for="search"
          >
            <b-form-input
              id="search"
              v-model="search"
              placeholder="Müşteri Adı, Firma Adı, Telefon No"
              @keydown.enter="searchData"
            />
          </b-form-group>
          <div class="text-center">
            <b-button
              type="submit"
              variant="primary"
              class="mr-50"
              @click="searchData"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              Ara
            </b-button>
            <b-button
              v-b-toggle.sidebar-invoice-add-new-customer
              type="submit"
              variant="danger"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              Müşteri Kartı Ekle
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card>
          <b-table
            striped
            hover
            responsive
            :fields="fields"
            :items="customers"
          >
            <template #cell(name)="data">
              <div>{{ data.item.name }}</div>
              <div class="text-primary">
                <small>{{ data.item.company_name }}</small>
              </div>
            </template>
            <template #cell(phone)="data">
              {{ data.item.phone }}
            </template>
            <template #cell(control)="data">
              <b-button
                :to="'/swapcars/add/' + data.item.id"
                variant="outline-primary"
                block
                size="sm"
              >
                Ekspertiz Oluştur
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <addCustomer :refresh-data="getCustomer" />
  </div>
</template>

<script>
import {
  BFormInput, BRow, BCol, BFormGroup, BButton, BCard, BTable, VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import addCustomer from '@/views/Crm/components/AddCustomer'

export default {
  name: 'Search',
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    addCustomer,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search: null,
      fields: [
        {
          key: 'name',
          label: 'Müşteri',
        },
        {
          key: 'phone',
          label: 'Telefon',
          thStyle: { width: '200px', textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '200px', textAlign: 'left', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'com_customer.id AS id',
          'com_customer.company_name AS company_name',
          'com_customer.name AS name',
          'com_customer.phone AS phone',
          'com_customer.email AS email',
        ],
        order_by: ['id', 'DESC'],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    customers() {
      return this.$store.getters['customers/getCustomers']
    },
  },
  created() {
    this.getCustomer()
  },
  methods: {
    searchData() {
      this.dataQuery.or_like = {
        'com_customer.company_name': this.search,
        'com_customer.name': this.search,
        'com_customer.phone': this.search,
      }
      this.getCustomer()
    },
    getCustomer() {
      this.$store.dispatch('customers/customersList', this.dataQuery)
    },
  },
}
</script>
